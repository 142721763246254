<template>
  <div class="wet-switch">
    <div
      class="wet-switch__button"
      :class="{
        'wet-switch__button_active': checkedInput,
        'wet-switch__button_disabled': disabled,
      }"
    >
      <input
        :id="uid"
        class="wet-switch__input"
        :type="type"
        :value="value"
        v-model="valuesInput"
        @click.stop
      >
      <div class="wet-switch__track"></div>
      <div class="wet-switch__thumb"></div>
    </div>
    <label v-if="label" :for="uid" class="wet-switch__label">{{ label }}</label>
    <div v-if="tooltip.title || tooltip.message" class="wet-switch__tip">
      <span class="wet-switch__tip-title">
        {{ tooltip.title }}
        <i class="icon-informer wet-switch__tip-icon"></i>
      </span>
      <div class="wet-switch__tip-message">
        {{ tooltip.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSwitch',
  model: {
    prop: 'values',
    event: 'checked',
  },
  props: {
    type: {
      type: String,
      default: 'checkbox',
      validator: (v) => ['checkbox', 'radio'].indexOf(v) !== -1,
    },
    label: {
      type: String,
      default: '',
    },
    value: { type: [String, Boolean], default: '' },
    values: { type: [Array, Boolean, String], default: false },
    disabled: Boolean,
    tooltip: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    checkedInput() {
      let checked = false;
      if (Array.isArray(this.valuesInput)) {
        checked = this.valuesInput.includes(this.value);
      } else {
        checked = this.values;
      }
      return checked;
    },
    valuesInput: {
      get() {
        return this.values;
      },
      set(val) {
        if (this.disabled) return;
        let checked;
        if (Array.isArray(this.values)) {
          checked = this.type === 'radio' ? val : this.toggle(val, this.values);
        } else {
          checked = val;
        }
        this.$emit('checked', checked);
      },
    },
    uid() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid;
    },
  },
  methods: {
    toggle(val, values) {
      const index = values.indexOf(val);
      let result = [...values];
      if (index === -1) {
        result = result.push(val);
      } else {
        result.splce(index, 1);
      }
    },
  },
};
</script>

<style lang="scss">
.wet-switch {
  --switch-size: 1.5rem;
  display: inline-flex;
  cursor: pointer;

  &__button {
    width: calc(var(--switch-size) * 1.75 + 2px);
    display: inline-flex;
    flex: 0 0 auto;
    height: calc(var(--switch-size) + 2px);
    position: relative;
    color: var(--gray);
    cursor: pointer;

    &_active {
      color: var(--success);
      --wet-switch-border-color: currentColor;
      --wet-switch-thumb-translate: calc(var(--switch-size) - var(--switch-size) * 0.25);
    }

    &_disabled {
      color: var(--success-light);
      cursor: not-allowed;
    }
  }

  &__input {
    padding: 0;
    margin: 0;
    border: none;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: inherit;
    user-select: none;
  }

  &__track {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    background-color: var(--wet-switch-border-color, white);
    pointer-events: none;
    border-radius: var(--border-radius);
    width: calc(var(--switch-size) * 1.75 + 2px);
    height: calc(var(--switch-size) + 2px);
    left: 0;
    position: absolute;
    opacity: .6;
    top: calc(50% - var(--switch-size) / 2);
    box-shadow: inset 0 2px 4px -1px rgba(black, .2);
    transition: all .2s;
  }

  &__thumb {
    box-shadow: 0 2px 4px -1px rgba(black, .4),
      0 4px 5px 0 rgba(black, .14),
      0 1px 10px 0 rgba(black, .12);
    pointer-events: none;
    border-radius: 50%;
    top: calc(50% - var(--switch-size) / 2 + 1px);
    left: 1px;
    height: var(--switch-size);
    position: relative;
    width: var(--switch-size);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: currentColor;
    transition: all .2s;
    transform: translate(var(--wet-switch-thumb-translate));
  }

  &__label {
    padding-left: 1rem;
    cursor: pointer;
  }

  &__tip {
    --wet-tip-show: 0;
    position: relative;
    transition: all .2s ease-in-out;
    padding: 0 .5rem;

    &:hover {
      --wet-tip-show: 1;
    }
  }

  &__tip-title {

  }

  &__tip-message {
    position: absolute;
    opacity: var(--wet-tip-show);
    pointer-events: none;
    font-size: .75em;
    z-index: 9999;
    background-color: rgba(0,0,0,0.8);
    color: white;
    padding: .5rem 1rem;
    width: 250px;
    max-width: 100vw;
    border-radius: .5rem;
    transition: all .3s ease-in-out;
    text-align: justify;
    margin-top: .5rem;
  }

  &__tip-icon {
    font-size: 1.25rem;
    color: gray;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--secondary);
    }
  }
}
</style>
